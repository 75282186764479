.check-icon {
  font-size: 25px;
  color: var(--teal);
  opacity: 0;
  animation: appear  1.5s ease 0.5s forwards;

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
