#how-to-use-container {
  background: var(--grey-background);
  margin-top: 60px;
  padding: 30px;
  border-radius: 10px;

  p {
    font-size: 1.2em;
  }

  #ghana-map {
    margin-top: 30px;
  }
}
