#search-images {
  width: 100%;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.card-body {
  p {
    font-weight: normal;
    font-size: 1.1em;
  }

  a {
    font-weight: normal;
    font-size: 1.1em;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  #search-images {
    grid-template-columns: repeat(auto-fill, minmax(250px, 0.8fr));
    justify-content: center;
  }
  .card-img-top {
    height: 220px;
    object-fit: cover;
    object-position: center;
  }
}
