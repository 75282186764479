.img-edit-preview {

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.rounded-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center;
}

.update-message {
  text-align: center;
  font-size: 1.3em;
}

.dashboard-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    font-weight: bold;
    font-size: 1.3em;

    &:hover {
      opacity: 0.8;
    }
  }
}
