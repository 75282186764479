

.property-head {
  font-size: 35px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1em;
}

.see-more {
  font-size: 20px;
  color: var(--teal-hover);
  text-align: center;
  width: 100px;
  margin: 10px auto;
  display: block;

  &:hover {
    text-decoration: none;
    color: var(--teal);
  }
}

.search-card {
  min-height: 340px;
}

.card {
  min-width: 260px;

  .card-action {
    a {
      &:not(.btn) {
        &:not(.btn-large) {
          &:not(.btn-floating) {
            color: var(--teal-hover);
            text-align: center;
            display: block;

            &:hover {
              color: var(--teal);
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.card-img-top {
  height: 200px;
  //object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 400px) {
  #hero-message {
    p {
      font-size: 1.0em;
    }

    h1 {
      font-size: 2.5em;
    }
  }
  #hero {
    align-items: center;
    justify-content: space-around;
  }
}

