.options {
  display: flex;
  justify-content: space-between;
  line-height: 10px;
  padding-top: 10px;
}

.options-link {
  color: #00695C;

  &:hover {
    color: #004D40;
    text-decoration: none;
  }
}

.list-group {
  font-size: 1.2em;
  font-weight: bold;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;

  .badge-container {
    width: 20%;
    padding-left: 16px;
    margin-bottom: 15px;
  }

  .badge {
    font-size: 0.8em;
  }

}

.ad-view-link {
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0.9;
  transition: opacity 0.3s linear;
  font-weight: normal;

  &:hover {
    opacity: 1.0;
  }
}

.list-group-item :first-child {
  margin-right: 0;
}

