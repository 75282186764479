footer {
  margin-top: 100px;
  background-color: var(--grey-background);
  padding: 40px 0 60px 0;
  font-weight: normal;
  bottom: 0;
  width: 100%;
  z-index: 99
}
#footer-social {
  font-size: 1.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  a {
    color: var(--grey-link);
    opacity: 0.8;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 1.0;
    }
  }

}

