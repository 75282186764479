.dash-profile-card {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
  }
  
  .profile-img {
    width: 70px;
    height: 70px;
  }

  .dash-profile-name {
    margin-left: 20px;
    text-align: center;

    @media screen and (max-width: 991px) {
      margin-left: 0;
    }

    h4 {
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .dash-edit-link {
    a {
      font-weight: bold;
      font-size: 1.2em;
    }
  }
}
