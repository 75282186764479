#hero {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  width: 100%;
  background: url("../../assets/img/namnso-ukpanah-6UqJTfoXIq8-unsplash.jpg") no-repeat center center scroll;
  background-size: cover;
  color: rgba(255, 255, 255, 1.00);

  #hero-social-icons {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1.5em;

    a {
      color: var(--color-white);
      margin-top: 25px;
      opacity: 0.6;
      transition: opacity 0.3s ease-in;

      &:hover {
        opacity: 1.0;
      }
    }
  }

  #hero-message {
    width: 50%;
    height: 30vh;

    @media screen and (max-width: 360px) {
      height: unset;
    }

    h1 {
      font-size: 2.5em;

      @media screen and (max-width: 480px){
        font-size: 1.6em;
      }
    }

    p {
      font-size: 1.5em;
      margin-top: 45px;
      line-height: 2em;

      @media screen and (max-width: 480px){
        font-size: 1.2em;
      }
    }
  }
}
