#err-main-div {
  text-align: justify-all;
  background: #004D44;
  color: white;
  display: flex;
  align-items: center;
  height: 100vh;
  h1 {
    margin: 0 0 10px;
    font-size: 5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  button {
    padding: 10px 20px;
    font-size: 15px;
    color: #004D44;
    background: white;
    border: 1px solid #004D44;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #004D44;
      border: 1px solid white;
      color: white;
    }
  }
}
.error {
  text-align: center;
  margin: auto;
  img {
    width: 100%;
  }
}
.error-message {
  text-align: center;
  margin-top: 30px;
}
h2 {
  margin: 0 0 10px;
}
h3 {
  margin: 0 0 10px;
}
.main-col {
  margin: auto;
}
@media only screen and (min-width: 600px) {
  .error-message{
    margin: auto;
    text-align: left;
  }
}

