//../../assets/img/20-cover.jpg

.about-text {
  margin-top: 60px;
}

.about-text p {
  font-size: 1.2em !important;
  line-height: 1.2em;
}
