.term-section {
  margin-top: 50px;
  h3 {
    font-weight: bold;
  }
}

.term-paragraph {
  font-size: 1.2em;
}
