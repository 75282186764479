.selectpicker {
  padding-left: 10px;
  option {
    &:hover {
      background-color: var(--teal);
    }
  }
}

.upload-button-label {
  display: flex;
  align-items: center;
  cursor: pointer;

  .upload-text {
    margin-left: 1em;
  }
}

.uploaded-images {
  h5 {
    font-weight: bold;
  }

  ul {
    li {
      font-size: 1.2em;
    }
  }
}

.uploading-message {
  opacity: 1;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  animation: loading 1.4s ease 0.5s infinite;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
