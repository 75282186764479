#no-ads-msg {
  text-align: center;
  height: 52vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 110px;
    height: 110px;
  }

  h3, h4 {
    margin-top: 15px;
    font-weight: bold;
  }
}
