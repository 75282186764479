.mh-100vh {
  min-height: 100vh;
}

#login-block {
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.4);
  z-index: 2;
}

@media (max-width: 991.98px) {
  #login-block {
    opacity: .95;
  }
  #bg-block {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -999;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75%;
  }
  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-50 {
    width: 50%;
  }
}

#google-signin {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
  background-color: #4c8bf5 !important;

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: inherit;
  }
}
