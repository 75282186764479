.misc-content-bg {
  background-image: url("../../assets/img/chair.jpg");
  background-size: cover ;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;


  @media screen and (max-width: 768px) {
    height: 480px;
  }

  .misc-content {

    color: black;
    padding: 30px 5em;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 380px) {
      padding-left: 1em;
      padding-right: 0;
    }


    h2 {
      font-weight: 700;
      font-size: 3em;

      @media screen and (max-width: 230px) {
        font-size: 1.5em;
      }
    }


  }
}

.get-started {
  margin-top: 30px;

  a {
    background-color: white;
    padding: 15px 20px;
    border-radius: 5px;
    font-weight: bold;
    color: black;
  }
}