.btn.btn-primary {
  color: #fff;
  background-color: var(--teal) !important;
  border-color: var(--teal) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 77, 64, 0.14), 0 3px 1px -2px rgba(0, 77, 64, 0.2), 0 1px 5px 0 rgba(0, 77, 64, 0.12);

  &:active {
    box-shadow: 0 14px 26px -12px rgba(0, 77, 64, 0.42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 77, 64, 0.2);

    &:focus {
      color: #fff;
      background-color: var(--teal);
      border-color: var(--teal-hover);
    }

    &:hover {
      color: #fff;
      background-color: var(--teal);
      border-color: var(--teal-hover);
    }
  }

  &:focus {
    box-shadow: 0 14px 26px -12px rgba(0, 77, 64, 0.42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 77, 64, 0.2);
    color: #fff;
    background-color: var(--teal) !important;
    border-color: var(--teal-hover) !important;
  }

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 77, 64, 0.42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 77, 64, 0.2);
    color: #fff;
    background-color: var(--teal) !important;
    border-color: var(--teal-hover) !important;
  }

  &:active.focus {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal-hover);
  }
}

.btn.btn-primary.focus {
  color: #fff;
  background-color: var(--teal) !important;
  border-color: var(--teal-hover) !important;
}

.btn.btn-primary.active.focus {
  color: #fff;
  background-color: var(--teal);
  border-color: var(--teal-hover);
}

.btn.btn-primary.active {
  &:focus {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal-hover);
  }

  &:hover {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal-hover);
  }
}

.open {
  > .btn.btn-primary.dropdown-toggle.focus {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal-hover);
  }

  > .btn.btn-primary.dropdown-toggle {
    &:focus {
      color: #fff;
      background-color: var(--teal);
      border-color: var(--teal-hover);
    }

    &:hover {
      color: #fff;
      background-color: var(--teal);
      border-color: var(--teal-hover);
    }
  }

  > .btn.btn-primary.inverted.dropdown-toggle.focus {
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }

  > .btn.btn-primary.inverted.dropdown-toggle {
    &:focus {
      color: var(--teal) !important;
      background-color: white !important;
      border-color: white !important;
    }

    &:hover {
      color: var(--teal) !important;
      background-color: white !important;
      border-color: white !important;
    }
  }
}

.show {
  > .btn.btn-primary.dropdown-toggle.focus {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal-hover);
  }

  > .btn.btn-primary.dropdown-toggle {
    &:focus {
      color: #fff;
      background-color: var(--teal);
      border-color: var(--teal-hover);
    }

    &:hover {
      color: #fff;
      background-color: var(--teal);
      border-color: var(--teal-hover);
    }
  }

  > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--teal-hover);
    border-color: var(--teal);
  }

  > .btn.btn-primary.inverted.dropdown-toggle.focus {
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }

  > .btn.btn-primary.inverted.dropdown-toggle {
    &:focus {
      color: var(--teal) !important;
      background-color: white !important;
      border-color: white !important;
    }

    &:hover {
      color: var(--teal) !important;
      background-color: white !important;
      border-color: white !important;
    }
  }

  > .btn-primary.inverted.dropdown-toggle {
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }
}

.btn-primary {
  &:not(:disabled) {
    &:not(.disabled).active {
      color: #fff;
      background-color: var(--teal-hover);
      border-color: var(--teal);
    }

    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: var(--teal-hover);
        border-color: var(--teal);
      }
    }
  }
}

.btn.btn-primary.inverted {
  color: var(--teal) !important;
  background-color: white !important;
  border-color: var(--teal) !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.12) !important;

  &:active {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;

    &:focus {
      color: var(--teal) !important;
      background-color: white !important;
      border-color: white !important;
    }

    &:hover {
      color: var(--teal) !important;
      background-color: white !important;
      border-color: white !important;
    }
  }

  &:focus {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }

  &:active.focus {
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }
}

.btn.btn-primary.inverted.focus {
  color: var(--teal) !important;
  background-color: white !important;
  border-color: white !important;
}

.btn.btn-primary.inverted.active.focus {
  color: var(--teal) !important;
  background-color: white !important;
  border-color: white !important;
}

.btn.btn-primary.inverted.active {
  &:focus {
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }

  &:hover {
    color: var(--teal) !important;
    background-color: white !important;
    border-color: white !important;
  }
}

.btn-primary.inverted {
  &:not(:disabled) {
    &:not(.disabled).active {
      color: var(--teal) !important;
      background-color: white !important;
      border-color: white !important;
    }

    &:not(.disabled) {
      &:active {
        color: var(--teal) !important;
        background-color: white !important;
        border-color: white !important;
      }
    }
  }
}
