a.nav-active {
  color: #004D40 !important;
  font-weight: bold !important;
}
.navbar {
  .navbar-brand {
    position: relative;
    color: inherit;
    height: 50px;
    font-size: 1.125rem;
    line-height: 30px;
    padding: .325rem 0 .625rem 0;
  }
}

.get-started-link {
  background-color: var(--teal);
  color: var(--color-white) !important;
  text-align: center;
  padding: 10px 20px!important;
}
